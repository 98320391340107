import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Memory Academy'
    }
  },
  {
    path: '/bd/sources',
    name: 'about',
    component: () => import('../views/SourcesView.vue'),    meta: {
      title: 'Memory Academy - Sources'
    }  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router
