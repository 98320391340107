<template>
  <div class="content">
    <div id="blob" />
    <div id="blur" />
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    // background blob animation
    const blob = document.getElementById("blob");
    document.body.onpointermove = event => {
        const {pageX, pageY} = event;

        blob.animate({
            left: `${pageX}px`,
            top: `${pageY}px`,
        }, { duration: 3000, fill: "forwards"});
    }
  },
}
</script>

<style>
@import '@/styles/style.css';
</style>
