<template>
  <div class="home">
    <div class="section">
      <div class="grid-container">
        <router-link
          class="logo"
          to="/"
        />
        <div class="hero" />
        <div class="form">
          <p>Je souhaite être informé(e) de la sortie de l'application</p>
          <div class="row">
            <input
              type="email"
              placeholder="Votre adresse Email"
            >
            <button type="button">
              Envoyer
            </button>
          </div>
          <p class="small">
            En vous inscrivant, vous serez tenu informé des avancées du développement de l’application.
          </p>
          <p class="small">
            Nous vous assurons que votre Email ne sera pas communiqué à des entreprises tierces.
          </p>
          <router-link
            to="bd/sources"
            class="page-link"
          >
            Références scientitiques
          </router-link>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-container">
        <div class="images">
          <img
            src="@/assets/Screen_1.png"
            alt="screen 1"
          >
          <img
            src="@/assets/Screen_2.png"
            alt="screen 2"
          >
        </div>
        <div class="text">
          <ul>
            <li>Enfin une application gratuite et sans pub !</li>
            <li>Statistiques gratuites pour visualiser vos progrés !</li>
            <li>Pour les Ados, Étudiants, Apprenants, Séniors</li>
          </ul>
          <p>Memory Academy propose des mini-jeux très funs pour entraîner votre mémoire.</p>
          <p>Évoluez à votre rythme et visualisez vos progrés grâce à vos statistiques personnelles gratuites.</p>
          <p>Aucune pub pour perturber votre expérience !</p>
          <p>
            Memory Academy vous offre également une section dans laquelle vous apprendrez le fonctionnement de la
            mémoire.
          </p>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-container">
        <div class="text">
          <p>Une application pour toute la famille !</p>
          <ul>
            <li>Aux adolescents qui souhaitent mémoriser efficacement leurs cours.</li>
            <li>Aux étudiants qui veulent optimiser leur mémorisation et leur concentration.</li>
            <li>Aux apprenants qui ont soif de connaissances.</li>
            <li>Aux Séniors qui désirent entretenir leur forme intellectuelle.</li>
          </ul>
        </div>
        <div class="images">
          <img
            src="@/assets/Screen_3.png"
            alt="screen 3"
          >
          <img
            src="@/assets/Screen_4.png"
            alt="screen 4"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'HomeView',
  components: {
  }
}
</script>
